import MenuCards from "@/components/MenuCards";
import AboutPage from "@/components/AboutPage";
import RandomNumber from "@/components/RandomNumber";
import SortingGame from "@/components/SortingGame";
import AdditionGame from "@/components/AdditionGame";
import SpellingGame from "@/components/SpellingGame";
import MultiplyGame from "@/components/MultiplyGame";
import DivideGame from "@/components/DivideGame";

import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes: [
    { path: "/", name: "home", component: MenuCards },
    { path: "/about", name: "about", component: AboutPage },
    { path: "/random-game", name: "randomGame", component: RandomNumber },
    { path: "/addition-game", name: "additionGame", component: AdditionGame },
    { path: "/sorting-game", name: "sortingGame", component: SortingGame },
    { path: "/spelling-game", name: "spellingGame", component: SpellingGame },
    { path: "/divide-game", name: "divideGame", component: DivideGame },
    { path: "/multiply-game", name: "multiplyGame", component: MultiplyGame },
  ],
});

export default router;
