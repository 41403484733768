<template>
  <div class="app">
    <header-bar></header-bar>
    <div class="content"><router-view></router-view></div>
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar.vue'
import FooterBar from '@/components/FooterBar.vue'
export default {
  name: 'App',
  components: {
    HeaderBar,
    FooterBar
  }
}
</script>

<style lang="scss" scoped>
.app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .content {
    max-width: 900px;
    margin: auto;
  }


  .button {
    margin: 10px;
  }

  .button.is-primary {
    background-color: var(--base-color);
  }
}
</style>