<script setup>
import { ref } from 'vue'
import Confetti from 'vue-confetti/src/confetti'

// Initialize confetti
const confetti = new Confetti({})

// Reactive references for state
const playerSolution = ref("")
const number1 = ref(0)
const number2 = ref(0)
const solutionInput = ref(null)

// Class binding to highlight an incorrect attempt
const productClass = ref("")

// Control flags for the game
const inProgress = ref(false)
const gameSetup = ref(false)

// Game parameters
const max = ref(20)
let currentScore = ref(0)
const winningScore = 10

// Randomly generate two numbers within the max range
const setupProduct = () => {
  number1.value = Math.floor(Math.random() * max.value)
  number2.value = Math.floor(Math.random() * max.value)
}

// Reset the game to start a new round
const resetGame = () => {
  inProgress.value = true
  setupProduct()
}

// Initial game setup
const setupGame = () => {
  gameSetup.value = true
  resetGame()
}

// Handle submission of the user’s solution
const submitSolution = () => {
  productClass.value = ""

  // Check if user's input matches the product
  if (playerSolution.value == number1.value * number2.value) {
    currentScore.value++
    playerSolution.value = ""
    setupProduct()
    solutionInput.value.focus()
  } else {
    productClass.value = "incorrect"
  }

  // If player has reached the winning score, trigger confetti
  if (currentScore.value === winningScore) {
    inProgress.value = false
    confetti.start()
    window.setTimeout(() => {
      confetti.stop()
      currentScore.value = 0
      resetGame()
      inProgress.value = true
    }, 7000)
  }
}
</script>

<template>
  <div class="game multiplication">
    <!-- If the game is set up, either show the game in progress or the "winner" message -->
    <div v-if="gameSetup">
      <div v-if="inProgress">
        <p>
          Enter the result of the multiplication below:
        </p>

        <div class="product">
          {{ number1 }} × {{ number2 }} = 
          <input
            class="solution"
            :class="productClass"
            ref="solutionInput"
            v-model="playerSolution"
            @keyup.enter.prevent="submitSolution"
          />
        </div>

        <div>
          <div class="button yes disable-touch-action" @click="submitSolution">
            Submit
          </div>
          <div>
            <progress id="file" :value="currentScore" :max="winningScore"></progress>
          </div>
        </div>
      </div>
      <div v-else class="winner">
        <p>Winner!</p>
      </div>
    </div>
    <!-- Before the game is set up, let the user choose the maximum multiplier -->
    <div class="setup" v-else>
      <p>Choose the maximum size of number to multiply</p>
      <div class="field">
        <label>Maximum</label>
        <input
          class="input"
          type="number"
          v-model="max"
          @focus="$event.target.select()"
        />
      </div>
      <div>
        <div class="button is-primary" @click="setupGame">
          Go
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.game {
  .product {
    font-size: 30px;
    margin: 30px;
  }

  input.solution {
    font-size: 30px;
    width: 80px;
    text-align: center;
    border: 1px solid rgba(200, 200, 200, 1);

    &:focus-visible,
    &:focus {
      outline: none;
    }

    &.incorrect {
      animation: pulse 1s 1;
    }
  }

  .button {
    border: 1px solid #ccc;
    display: inline-block;
    min-width: 100px;
    cursor: pointer;

    &.no {
      background-color: #ca0011;
      color: white;
    }

    &.yes {
      background-color: #12762b;
      color: white;
    }

    &.disable-touch-action {
      touch-action: manipulation;
    }
  }

  progress {
    margin: 20px;
  }

  @keyframes pulse {
    0% {
      border-color: rgba(200, 200, 200, 1);
    }
    70% {
      border-color: rgba(200, 30, 30, 1);
    }
    100% {
      border-color: rgba(200, 200, 200, 1);
    }
  }
}
</style>
